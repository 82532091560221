module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ned Taxi Genève","short_name":"Ned Taxi","description":"Votre service de transport privé fiable et flexible.","lang":"fr","display":"standalone","icon":"src/images/favicon.svg","start_url":"/","background_color":"#F7BC1D","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"Ned Taxi Genf","short_name":"Ned Taxi","description":"Ihr zuverlässiger und flexibler privater Transportdienst."},{"start_url":"/en/","lang":"en","name":"Ned Taxi Geneva","short_name":"Ned Taxi","description":"Your reliable and flexible private transportation service."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c2240de92953d74d52e9d29dc96093eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_5b5vknVFLMWAfx173KEj0K3KU1MVUuwjwLWod4BHSKo","apiHost":"https://eu.posthog.com","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/master/ned-taxi-geneve/src/translation","languages":["en","de"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ned-taxi-geneve.ch","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
